<template>
  <v-dialog
    v-model="show"
    max-width="500px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          Enviar SMS
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon
              v-text="'mdi-close'"
            />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <v-row>
          <v-col>
            <p class="display-1 mb-0">
              Confirme o envio dos SMS's com o link para responder a pesquisa
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue"
          outlined
          @click="showDialogConfirmation = true"
        >
          <v-icon
            left
            v-text="'mdi-send'"
          />
          Enviar
        </v-btn>
      </v-card-actions>
    </v-card>

    <dialog-confirm-send-sms
      v-model="showDialogConfirmation"
      :loading="loading"
      @send="send"
    />

  </v-dialog>
</template>

<script>
  import surveysApi from '@/api/surveys'

  export default {

    components: {
      DialogConfirmSendSms: () => import('@/components/dialog/DialogConfirmSendSms'),
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      survey: {
        type: Object,
        default: () => { },
      },
    },

    data () {
      return {
        loading: false,
        showDialogConfirmation: false,
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    methods: {
      async send () {
        this.loading = true

        try {
          const response = await surveysApi.sendSms(this.survey.id, { })

          this.$snackbar.show({ color: 'success', message: response.data.message })
          this.showDialogConfirmation = false
          this.show = false
          this.$emit('send')
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
          return
        } finally {
          this.loading = false
        }
      },
    },

  }
</script>
